@import '~primereact/resources/themes/saga-blue/theme.css';
@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';

@import './assets/style/_overrides.scss';
@import '~primeflex/src/_variables.scss';
@import '~primeflex/src/_grid.scss';
@import '~primeflex/src/_formlayout.scss';
@import '~primeflex/src/_display.scss';
@import '~primeflex/src/_text.scss';
@import '~primeflex/src/flexbox/_flexbox.scss';
@import '~primeflex/src/_spacing.scss';
@import '~primeflex/src/_elevation.scss';

html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

iframe {
  pointer-events: none;
}


