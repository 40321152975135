.container {
  max-width: 600px;
  text-align: center;

  h1 {
    margin: 30px 0;
    font-size: 15em;
    line-height: 1;
    letter-spacing: -1px;
  }
}