.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  margin-top: -25%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.centered-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}
